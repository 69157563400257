import React from "react";
import { Link } from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import { Button, Collapse, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown, NavbarBrand, Navbar, NavItem, Nav, NavLink, Container } from "reactstrap";
// import LogoBlanco from '../../assets/img/logo.png'
// import LogoColor from '../../assets/img/logo-color.png'
import Logo from '../../assets/img/logo.png'

function NavbarProyecto() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  // const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  // const [imgLogo, setImgLogo] = React.useState(LogoBlanco)
  React.useEffect(() => {
    // initialise
    
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
        // setImgLogo(LogoColor)
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
        // setImgLogo(LogoBlanco)
      }
    };
    
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      <Navbar className={classnames("fixed-top", navbarColor)} expand="lg" id="navbar-main">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" className="py-0" to="/#header" tag={Link}>
              <img src={Logo} style={{height:35}} alt="logo fundo zapallar"/>
            </NavbarBrand>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  El Proyecto
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/#informacion" tag={Link}>
                    Información 
                  </DropdownItem>
                  <DropdownItem to="/#paisajismo" tag={Link}>
                    Paisajismo
                  </DropdownItem>
                  <DropdownItem to="/#atributos" tag={Link}>
                    Atributos
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                  <NavLink to="/#masterplan" tag={Link}>
                      Masterplan 360
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/#galeria" tag={Link}>
                      Galeria
                  </NavLink>
              </NavItem>
              <NavItem>
                  <NavLink to="/#ubicacion" tag={Link}>
                      Ubicación
                  </NavLink>
              </NavItem>
              <NavItem>
                <Button
                  className="btn-round outline"
                  color="info"
                  to="/#contacto"
                  tag={Link}
                >
                  <i className="nc-icon nc-phone" /> Contacto
                </Button>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarProyecto;
