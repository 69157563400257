import React from "react";
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Formulario from '../Formulario'
import Leria from '../../assets/img/leria.jpg'
import Pyg from '../../assets/img/pyg1.png' 
const Contacto = () => {
  return (
      <div className="section section-contactus cd-section" id="contacto">
        <div className="contactus-1 section-image"
          style={{
            backgroundImage:
              "url(" + require("assets/img/sections/bg-contacto.jpg") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <Card className="card-contact no-transition">
                  <CardTitle className="text-center" tag="h3">
                    Contáctanos
                  </CardTitle>
                  <Row>
                    <Col className="ml-auto" md="5">
                      <CardBody>
                        <div>
                          <img src={Leria} alt="logo leria propiedades"/>
                          <div className="description">
                            <p className="pt-2">
                              Avenida Cachagua 324 of 7, Cachagua<br/>
                              Teléfonos: <br/>
                              +56966461840 <br/>                              
                              +56332772349 <br/>
                              www.leria.com
                            </p>
                          </div>
                        </div>
                      </CardBody>
                      <CardBody>
                        <div>
                          <img src={Pyg} style={{width:125}} alt="logo pyg propiedades"/>
                          <div className="description">
                            <p className="pt-2">
                              Málaga 333, Las Condes<br />
                              Teléfonos: <br/>
                              +56224102000 <br/>
                              +56224202016 <br/>
                              +56224102025 <br/>
                              www.pyg.cl
                            </p>
                          </div>
                        </div>
                      </CardBody>
                    </Col>
                    <Col className="mr-auto" md="5">
                      <Formulario/>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
  )
}

export default Contacto
