import ReactGA from 'react-ga4'
export const initGA = (trackingID) => {           
    ReactGA.initialize(trackingID); 
}
export const PageView = () => {
    // ReactGA.pageview(window.location.pathname + window.location.search)
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Fundo Zapallar" });
}
export const Event = (category, action , label) => {
    ReactGA.event({
        category: category,
        action: action,
        label: label
    })
} 