import React from 'react'
import Mapa from '../Googlemaps'
import { Container, Button } from 'reactstrap'

const mapsSelector = () => {
    if /* if we're on iOS, open in Apple Maps */
      ((navigator.platform.indexOf("iPhone") !== -1) || 
      (navigator.platform.indexOf("iPad") !== -1) || 
      (navigator.platform.indexOf("iPod") !== -1))
      window.open("maps://maps.google.com/maps?daddr=-32.569796,-72.93981&amp;ll=");
    else /* else use Google */
      window.open("https://maps.google.com/maps?daddr=-32.569796,-71.450710&amp;ll=");
}

const Ubicacion = () => {
    return(
        <>
            <div className="text-center" id="ubicacion">
                <Mapa/>
            </div>
            <Container className="pt-2">
                <Button 
                    className="btn-round outline"
                    color="info" 
                    onClick={() => mapsSelector()}
                >   
                    <div className="row align-items-center text-white">
                        <i className="nc-icon nc-map-pin x2 ml-2 pb-1 px-1"></i>
                        <span className="pt-1 mr-2">
                        Abre con tu App
                        </span>
                    </div>
                </Button>
            </Container>
        </>
    )
}
export default Ubicacion