import React from "react";

// core components
import NavbarProyecto from '../../components/NavbarProyecto'
import Gracias from '../../components/Gracias'

function Thank(){
    return (
    <>
        <Gracias/>
    </>
    );
}

export default Thank