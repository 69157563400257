import React from "react";
import { BrowserRouter, Route } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import Thank from '../Pages/Thank';
import Switch from "react-bootstrap-switch";

function App() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index-page");

    var contact = document.getElementById("contacto");
    if(contact){
      // contact.scrollIntoView({
        // behavior: "smooth",
        // block: "start",
        // inline: "nearest"
      // });
    }
    //window.scrollTo(0, 0);
    //document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("index-page");
    };
  });
  return (
    <BrowserRouter>
          <>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/gracias" component={Thank} />
          </>
    </BrowserRouter>
  );
}

export default App;