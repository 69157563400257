import React from "react";
import { withRouter } from 'react-router-dom'
// reactstrap components
import { Container, Row, Col, Carousel, CarouselItem, CarouselIndicators, Button} from "reactstrap";
import Logo from '../../assets/img/logo.png';
import ReactGA from 'react-ga4';
  //ReactGA.initialize( ' UA-000000-01 ');
  // ReactGA.pageview("/");
  ReactGA.send({ hitType: "pageview", page: "/gracias", title: "Fundo Zapallar - gracias" });

/* import HomePage from '../Pages/HomePage' */
// core components
const items = [
    {
      src: "url(" + require("assets/img/sections/v2.jpg") + ")",
      content: (
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h1 className="title">Gracias por Contactarnos!</h1>
              <h5>Pronto un ejecutivo se contactará para entrar en mas detalles </h5>
            </Col>
          </Row>
        </Container>
      ),
      altText: "",
      caption: ""
    }
  ];
const HeaderProyecto = (props) => {
    const { history } = props;
        // carousel - header 3
    const [activeIndex, setActiveIndex] = React.useState(0);
    const [animating, setAnimating] = React.useState(false);
    const onExiting = () => {
        setAnimating(true);
    };
    const onExited = () => {
        setAnimating(false);
    };
    const next = () => {
        if (animating) return;
        const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };
    const previous = () => {
        if (animating) return;
        const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    const goToIndex = newIndex => {
        if (animating) return;
        setActiveIndex(newIndex);
    };
    return(
      <div className="header-3" id="header">
        <div className="page-carousel">
          <div className="filter" />
            <Carousel activeIndex={activeIndex} next={next} previous={previous}>
              <CarouselIndicators
                items={items}
                activeIndex={activeIndex}
                onClickHandler={goToIndex}
              />
                {
                    items.map(item => {
                    return (
                    <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                    >
                        <div
                        className="page-header"
                        style={{ backgroundImage: item.src }}
                        >
                            <div className="logo-center">
                              <div>
                                <img src={Logo} style={{height:35, width:350}} alt="logo fundo zapallar"/>
                              </div>
                            </div>
                            <div className="filter" />
                            <div className="content-center">
                              {item.content}
                              <br/>
                              <Button
                                className="btn-round outline"
                                color="info"
                                onClick={() => history.push('/')}
                                > Volver
                              </Button>
                            </div>
                        </div>
                    </CarouselItem>
                    )})
                }
              
          </Carousel>
        </div>
      </div>
  )
}
export default withRouter(HeaderProyecto);