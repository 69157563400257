import React from "react";
import { useState, useRef, useCallback, useEffect } from "react";
import LightGallery from "lightgallery/react";
import Plano from '../../assets/img/plano_fz.jpg';

// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";

// If you want you can use SCSS instead of css
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";

// import plugins if you need
import lgThumbnail from "lightgallery/plugins/thumbnail";
import lgZoom from "lightgallery/plugins/zoom";

const GalleryMaps = () => {
  const lightGallery = useRef([]);

  const [idLoteSelect, setIdLoteSelect] = useState(0);

  // core components
  const items = [
    {
      id: 1,
      name: "d01",
      title: "Fundo Zapallar, Manzana D. Lote 1",
      description: "Manzana D. Lote 1",
      status: "Vendido",
      surface: "4.702,32 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 1 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 4.702,32 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery' style='z-index: 1000;'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 2,
      name: "d02",
      title: "Fundo Zapallar, Manzana D. Lote 2",
      description: "Manzana D. Lote 2",
      status: "Vendido",
      surface: "4.228,74 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 2 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.228,74 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 3,
      name: "d03",
      title: "Fundo Zapallar, Manzana D. Lote 3",
      description: "Manzana D. Lote 3",
      status: "Vendido",
      surface: "4.556,26 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 3 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.556,26 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 4,
      name: "d04",
      title: "Fundo Zapallar, Manzana D. Lote 4",
      description: "Manzana D. Lote 4",
      status: "Vendido",
      surface: "4.104,39 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 4 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.104,39 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 5,
      name: "d05",
      title: "Fundo Zapallar, Manzana D. Lote 5",
      description: "Manzana D. Lote 5",
      status: "Vendido",
      surface: "4.389,55 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 5 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.389,55 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 6,
      name: "d06",
      title: "Fundo Zapallar, Manzana D. Lote 6",
      description: "Manzana D. Lote 6",
      status: "Vendido",
      surface: "4.389,55 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 6 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.389,55 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 7,
      name: "d07",
      title: "Fundo Zapallar, Manzana D. Lote 7",
      description: "Manzana D. Lote 7",
      status: "Vendido",
      surface: "3.697,6 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 7 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.697,6 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 8,
      name: "e08",
      title: "Fundo Zapallar, Manzana E. Lote 8",
      description: "Manzana E. Lote 8",
      status: "Vendido",
      surface: "4.389,55 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 8 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.389,55 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 9,
      name: "e09",
      title: "Fundo Zapallar, Manzana E. Lote 9",
      description: "Manzana E. Lote 9",
      status: "Vendido",
      surface: "3.253,54 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 9 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.253,54 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 10,
      name: "e10",
      title: "Fundo Zapallar, Manzana E. Lote 10",
      description: "Manzana E. Lote 10",
      status: "Vendido",
      surface: "3.199,81 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.199,81 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 11,
      name: "e11",
      title: "Fundo Zapallar, Manzana E. Lote 11",
      description: "Manzana E. Lote 11",
      status: "Vendido",
      surface: "4.654,44 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.654,44 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 12,
      name: "e12",
      title: "Fundo Zapallar, Manzana E. Lote 12",
      description: "Manzana E. Lote 12",
      status: "Vendido",
      surface: "4.654,45 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 12 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.654,45 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 13,
      name: "c27",
      title: "Fundo Zapallar, Manzana C. Lote 27",
      description: "Manzana C. Lote 27",
      status: "Vendido",
      surface: "2.404,81 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 27 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.404,81 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 14,
      name: "c28",
      title: "Fundo Zapallar, Manzana C. Lote 28",
      description: "Manzana C. Lote 28",
      status: "Vendido",
      surface: "2.273,5 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 28 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.273,5 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 15,
      name: "c29",
      title: "Fundo Zapallar, Manzana C. Lote 29",
      description: "Manzana C. Lote 29",
      status: "Vendido",
      surface: "2.373,9 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 29 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.373,9 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 16,
      name: "c30",
      title: "Fundo Zapallar, Manzana C. Lote 30",
      description: "Manzana C. Lote 30",
      status: "Vendido",
      surface: "2.104,52 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 30 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.104,52 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 17,
      name: "c31",
      title: "Fundo Zapallar, Manzana C. Lote 31",
      description: "Manzana C. Lote 31",
      status: "Vendido",
      surface: "2.356,85 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 31 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.356,85 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 18,
      name: "d12",
      title: "Fundo Zapallar, Manzana D. Lote 12",
      description: "Manzana D. Lote 12",
      status: "Vendido",
      surface: "3.096,72 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 12 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.096,72 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 19,
      name: "d13",
      title: "Fundo Zapallar, Manzana D. Lote 13",
      description: "Manzana D. Lote 13",
      status: "Vendido",
      surface: "2.443,93 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 13 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.443,93 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 20,
      name: "d14",
      title: "Fundo Zapallar, Manzana D. Lote 14",
      description: "Manzana D. Lote 14",
      status: "Vendido",
      price: "7,48 UF/m2",
      surface: "2044,84 m2",
      src: [
        require("assets/img/gallery/lotes/sitio-14/IMG_1401.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1402.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1403.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1404.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1405.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1406.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1407.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1408.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1409.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1410.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1411.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1412.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1413.jpg"),
        require("assets/img/gallery/lotes/sitio-14/IMG_1414.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 14 </h4><p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2044,84 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 21,
      name: "d15",
      title: "Fundo Zapallar, Manzana D. Lote 15",
      description: "Manzana D. Lote 15",
      status: "Vendido",
      surface: "2.577,32 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 15 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.577,32 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 22,
      name: "d16",
      title: "Fundo Zapallar, Manzana D. Lote 16",
      description: "Manzana D. Lote 16",
      status: "Vendido",
      surface: "2.377,29 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 16 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.377,29 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 23,
      name: "d17",
      title: "Fundo Zapallar, Manzana D. Lote 17",
      description: "Manzana D. Lote 17",
      status: "Vendido",
      surface: "2.906,28 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 17 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.906,28 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 24,
      name: "d18",
      title: "Fundo Zapallar, Manzana D. Lote 18",
      description: "Manzana D. Lote 18",
      status: "Vendido",
      surface: "3.068,33 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 18 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.068,33 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 25,
      name: "d19",
      title: "Fundo Zapallar, Manzana D. Lote 19",
      description: "Manzana D. Lote 19",
      status: "Vendido",
      surface: "3.694,78 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 19 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.694,78 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 26,
      name: "d20",
      title: "Fundo Zapallar, Manzana D. Lote 20",
      description: "Manzana D. Lote 20",
      status: "Vendido",
      price: "2,53 UF/m2",
      surface: "4.037,9 m2",
      src: [
        require("assets/img/gallery/lotes/sitio-20/IMG_2001.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2002.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2003.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2004.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2005.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2006.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2007.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2008.jpg"),
        require("assets/img/gallery/lotes/sitio-20/IMG_2009.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 20 </h4>  <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 4.037,9 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 27,
      name: "d21",
      title: "Fundo Zapallar, Manzana D. Lote 21",
      description: "Manzana D. Lote 21",
      status: "Vendido",
      price: "3,39 UF/m2",
      surface: "2.920,14 m2",
      src: [
        require("assets/img/gallery/lotes/sitio-21/IMG_2101.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2102.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2103.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2104.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2105.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2106.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2107.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2108.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2109.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2110.jpg"),
        require("assets/img/gallery/lotes/sitio-21/IMG_2111.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 21 </h4>  <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.920,14 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 28,
      name: "d22",
      title: "Fundo Zapallar, Manzana D. Lote 22",
      description: "Manzana D. Lote 22",
      status: "Vendido",
      price: "2,58 UF/m2",
      surface: "3.875,89 m2",
      src: [
        require("assets/img/gallery/lotes/sitio-22/IMG_2201.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2202.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2203.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2204.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2205.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2206.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2207.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2208.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2209.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2210.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2211.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2212.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2213.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2214.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2215.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2216.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2217.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2218.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2219.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2220.jpg"),
        require("assets/img/gallery/lotes/sitio-22/IMG_2221.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 22 </h4>  <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 3.875,89 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 29,
      name: "d23",
      title: "Fundo Zapallar, Manzana D. Lote 23",
      description: "Manzana D. Lote 23",
      status: "No Disponible",
      surface: "4.411 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 23 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 4.411 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 30,
      name: "d24",
      title: "Fundo Zapallar, Manzana D. Lote 24",
      description: "Manzana D. Lote 24",
      status: "No Disponible",
      surface: "2.582,52 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 24 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 2.582,52 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 31,
      name: "d25",
      title: "Fundo Zapallar, Manzana D. Lote 25",
      description: "Manzana D. Lote 25",
      status: "No Disponible",
      surface: "2.254,3 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 25 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 2.254,3 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 32,
      name: "d26",
      title: "Fundo Zapallar, Manzana D. Lote 26",
      description: "Manzana D. Lote 26",
      status: "No Disponible",
      surface: "2.316,7 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 26 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 2.316,7 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 33,
      name: "d27",
      title: "Fundo Zapallar, Manzana D. Lote 27",
      description: "Manzana D. Lote 27",
      status: "Vendido",
      surface: "2.798,37 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 27 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.798,37 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 34,
      name: "d28",
      title: "Fundo Zapallar, Manzana D. Lote 28",
      description: "Manzana D. Lote 28",
      status: "Vendido",
      surface: "2.480,38 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 28 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.480,38 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 35,
      name: "d29",
      title: "Fundo Zapallar, Manzana D. Lote 29",
      description: "Manzana D. Lote 29",
      status: "Vendido",
      surface: "2.306,62 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 29 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.306,62 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 36,
      name: "d30",
      title: "Fundo Zapallar, Manzana D. Lote 30",
      description: "Manzana D. Lote 30",
      status: "Vendido",
      surface: "2.284,33 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 30 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.284,33 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 37,
      name: "d31",
      title: "Fundo Zapallar, Manzana D. Lote 31",
      description: "Manzana D. Lote 31",
      status: "Vendido",
      surface: "2.106,7 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 31 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.106,7 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 38,
      name: "d32",
      title: "Fundo Zapallar, Manzana D. Lote 32",
      description: "Manzana D. Lote 32",
      status: "Vendido",
      surface: "2.189,79 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 32 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.189,79 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 39,
      name: "d33",
      title: "Fundo Zapallar, Manzana D. Lote 33",
      description: "Manzana D. Lote 33",
      status: "No Disponible",
      surface: "3.149,29 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 33 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 3.149,29 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 40,
      name: "d34",
      title: "Fundo Zapallar, Manzana D. Lote 34",
      description: "Manzana D. Lote 34",
      status: "No Disponible",
      surface: "3.657,68 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 34 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 3.657,68 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 41,
      name: "e01",
      title: "Fundo Zapallar, Manzana E. Lote 01",
      description: "Manzana E. Lote 01",
      status: "Vendido",
      price: "2,22 UF/m2",
      surface: "5.641,62 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 01 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 5.641,62 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 42,
      name: "f01",
      title: "Fundo Zapallar, Manzana F. Lote 01",
      description: "Manzana F. Lote 01",
      status: "Disponible",
      price: "4,39 UF/m2",
      surface: "2.935,78 m2",
      src: [
        require("assets/img/gallery/lotes/F/F01/F01_1.jpg"),
        require("assets/img/gallery/lotes/F/F01/F01_2.jpg"),
        require("assets/img/gallery/lotes/F/F01/F01_3.jpg"),
        require("assets/img/gallery/lotes/F/F01/F01_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 01 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.935,78 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 43,
      name: "f02",
      title: "Fundo Zapallar, Manzana F. Lote 02",
      description: "Manzana F. Lote 02",
      status: "Disponible",
      price: "4,33 UF/m2",
      surface: "2.424,74 m2",
      src: [
        require("assets/img/gallery/lotes/F/F02/F02_1.jpg"),
        require("assets/img/gallery/lotes/F/F02/F02_2.jpg"),
        require("assets/img/gallery/lotes/F/F02/F02_2.jpg"),
        require("assets/img/gallery/lotes/F/F02/F02_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 02 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.424,74 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 44,
      name: "f03",
      title: "Fundo Zapallar, Manzana F. Lote 03",
      description: "Manzana F. Lote 03",
      status: "Vendido",
      price: "4,55 UF/m2",
      surface: "2.526,24 m2",
      src: [
        require("assets/img/gallery/lotes/F/F03/F03_1.jpg"),
        require("assets/img/gallery/lotes/F/F03/F03_2.jpg"),
        require("assets/img/gallery/lotes/F/F03/F03_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 03 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.526,24 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 45,
      name: "f04",
      title: "Fundo Zapallar, Manzana F. Lote 04",
      description: "Manzana F. Lote 04",
      status: "Vendido",
      price: "4,89 UF/m2",
      surface: "2.659,14 m2",
      src: [
        require("assets/img/gallery/lotes/F/F04/F04_1.jpg"),
        require("assets/img/gallery/lotes/F/F04/F04_2.jpg"),
        require("assets/img/gallery/lotes/F/F04/F04_3.jpg"),
        require("assets/img/gallery/lotes/F/F04/F04_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 04 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.659,14 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 46,
      name: "f05",
      title: "Fundo Zapallar, Manzana F. Lote 05",
      description: "Manzana F. Lote 05",
      status: "Disponible",
      price: "5,07 UF/m2",
      surface: "2.663,97 m2",
      src: [
        require("assets/img/gallery/lotes/F/F05/F05_1.jpg"),
        require("assets/img/gallery/lotes/F/F05/F05_2.jpg"),
        require("assets/img/gallery/lotes/F/F05/F05_3.jpg"),
        require("assets/img/gallery/lotes/F/F05/F05_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 05 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.663,97 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 47,
      name: "f06",
      title: "Fundo Zapallar, Manzana F. Lote 06",
      description: "Manzana F. Lote 06",
      status: "Vendido",
      price: "6,77 UF/m2",
      surface: "2.215,65 m2",
      src: [
        require("assets/img/gallery/lotes/F/F06/F06_1.jpg"),
        require("assets/img/gallery/lotes/F/F06/F06_2.jpg"),
        require("assets/img/gallery/lotes/F/F06/F06_3.jpg"),
        require("assets/img/gallery/lotes/F/F06/F06_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 06 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.215,65 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 48,
      name: "f07",
      title: "Fundo Zapallar, Manzana F. Lote 07",
      description: "Manzana F. Lote 07",
      status: "Vendido",
      price: "5,01 UF/m2",
      surface: "2.494,54 m2",
      src: [
        require("assets/img/gallery/lotes/F/F07/F07_1.jpg"),
        require("assets/img/gallery/lotes/F/F07/F07_2.jpg"),
        require("assets/img/gallery/lotes/F/F07/F07_3.jpg"),
        require("assets/img/gallery/lotes/F/F07/F07_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 07 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.494,54 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 49,
      name: "f08",
      title: "Fundo Zapallar, Manzana F. Lote 08",
      description: "Manzana F. Lote 08",
      status: "Vendido",
      price: "5,68 UF/m2",
      surface: "2.199,73 m2",
      src: [
        require("assets/img/gallery/lotes/F/F08/F08_1.jpg"),
        require("assets/img/gallery/lotes/F/F08/F08_2.jpg"),
        require("assets/img/gallery/lotes/F/F08/F08_3.jpg"),
        require("assets/img/gallery/lotes/F/F08/F08_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 08 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.199,73 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 50,
      name: "f09",
      title: "Fundo Zapallar, Manzana F. Lote 09",
      description: "Manzana F. Lote 09",
      status: "Vendido",
      price: "6,90 UF/m2",
      surface: "2.102,18 m2",
      src: [
        require("assets/img/gallery/lotes/F/F09/F09_1.jpg"),
        require("assets/img/gallery/lotes/F/F09/F09_2.jpg"),
        require("assets/img/gallery/lotes/F/F09/F09_3.jpg"),
        require("assets/img/gallery/lotes/F/F09/F09_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 09 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.102,18 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 51,
      name: "f10",
      title: "Fundo Zapallar, Manzana F. Lote 10",
      description: "Manzana F. Lote 10",
      status: "Vendido",
      price: "6,12 UF/m2",
      surface: "2.044,15 m2",
      src: [
        require("assets/img/gallery/lotes/F/F10/F10_1.jpg"),
        require("assets/img/gallery/lotes/F/F10/F10_2.jpg"),
        require("assets/img/gallery/lotes/F/F10/F10_3.jpg"),
        require("assets/img/gallery/lotes/F/F10/F10_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.044,15 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 52,
      name: "f11",
      title: "Fundo Zapallar, Manzana F. Lote 11",
      description: "Manzana F. Lote 11",
      status: "Disponible",
      price: "5,59 UF/m2",
      surface: "2.056,29 m2",
      src: [
        require("assets/img/gallery/lotes/F/F11/F11_1.jpg"),
        require("assets/img/gallery/lotes/F/F11/F11_2.jpg"),
        require("assets/img/gallery/lotes/F/F11/F11_3.jpg"),
        require("assets/img/gallery/lotes/F/F11/F11_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 11 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.056,29 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 53,
      name: "f12",
      title: "Fundo Zapallar, Manzana F. Lote 12",
      description: "Manzana F. Lote 12",
      status: "Disponible",
      price: "4,46 UF/m2",
      surface: "2.356,79 m2",
      src: [
        require("assets/img/gallery/lotes/F/F12/F12_1.jpg"),
        require("assets/img/gallery/lotes/F/F12/F12_2.jpg"),
        require("assets/img/gallery/lotes/F/F12/F12_3.jpg"),
        require("assets/img/gallery/lotes/F/F12/F12_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 12 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.356,79 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 54,
      name: "f13",
      title: "Fundo Zapallar, Manzana F. Lote 13",
      description: "Manzana F. Lote 13",
      status: "Disponible",
      price: "5,92 UF/m2",
      surface: "2.281,25 m2",
      src: [
        require("assets/img/gallery/lotes/F/F13/F13_1.jpg"),
        require("assets/img/gallery/lotes/F/F13/F13_2.jpg"),
        require("assets/img/gallery/lotes/F/F13/F13_3.jpg"),
        require("assets/img/gallery/lotes/F/F13/F13_4.jpg"),
        require("assets/img/gallery/lotes/F/F13/F13_5.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana F. Lote 13 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.281,25 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 55,
      name: "g02",
      title: "Fundo Zapallar, Manzana G. Lote 02",
      description: "Manzana G. Lote 02",
      status: "Vendido",
      price: "4,81 UF/m2",
      surface: "2.284,67 m2",
      src: [
        require("assets/img/gallery/lotes/G/G02/G02_1.jpg"),
        require("assets/img/gallery/lotes/G/G02/G02_2.jpg"),
        require("assets/img/gallery/lotes/G/G02/G02_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 02 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.284,67 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 56,
      name: "g03",
      title: "Fundo Zapallar, Manzana G. Lote 03",
      description: "Manzana G. Lote 03",
      status: "Vendido",
      price: "5,12 UF/m2",
      surface: "2.343,97 m2",
      src: [
        require("assets/img/gallery/lotes/G/G03/G03_1.jpg"),
        require("assets/img/gallery/lotes/G/G03/G03_2.jpg"),
        require("assets/img/gallery/lotes/G/G03/G03_3.jpg"),
        require("assets/img/gallery/lotes/G/G03/G03_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 03 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.343,97 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 57,
      name: "g04",
      title: "Fundo Zapallar, Manzana G. Lote 04",
      description: "Manzana G. Lote 04",
      status: "Vendido",
      price: "5,70 UF/m2",
      surface: "2.368,75 m2",
      src: [
        require("assets/img/gallery/lotes/G/G04/G04_1.jpg"),
        require("assets/img/gallery/lotes/G/G04/G04_2.jpg"),
        require("assets/img/gallery/lotes/G/G04/G04_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 04 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.368,75 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 58,
      name: "g05",
      title: "Fundo Zapallar, Manzana G. Lote 05",
      description: "Manzana G. Lote 05",
      status: "Vendido",
      price: "6,01 UF/m2",
      surface: "2.746,03 m2",
      src: [
        require("assets/img/gallery/lotes/G/G05/G05_1.jpg"),
        require("assets/img/gallery/lotes/G/G05/G05_2.jpg"),
        require("assets/img/gallery/lotes/G/G05/G05_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 05 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.746,03 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 59,
      name: "g06",
      title: "Fundo Zapallar, Manzana G. Lote 06",
      description: "Manzana G. Lote 06",
      status: "Vendido",
      price: "7,24 UF/m2",
      surface: "2.277,46 m2",
      src: [
        require("assets/img/gallery/lotes/G/G06/G06_1.jpg"),
        require("assets/img/gallery/lotes/G/G06/G06_2.jpg"),
        require("assets/img/gallery/lotes/G/G06/G06_3.jpg"),
        require("assets/img/gallery/lotes/G/G06/G06_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 06 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.277,46 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 60,
      name: "g07",
      title: "Fundo Zapallar, Manzana G. Lote 07",
      description: "Manzana G. Lote 07",
      status: "Vendido",
      price: "7,85 UF/m2",
      surface: "2.102,9 m2",
      src: [
        require("assets/img/gallery/lotes/G/G07/G07_1.jpg"),
        require("assets/img/gallery/lotes/G/G07/G07_2.jpg"),
        require("assets/img/gallery/lotes/G/G07/G07_3.jpg"),
        require("assets/img/gallery/lotes/G/G07/G07_4.jpg"),
        require("assets/img/gallery/lotes/G/G07/G07_5.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 07 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.102,9 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 61,
      name: "g08",
      title: "Fundo Zapallar, Manzana G. Lote 08",
      description: "Manzana G. Lote 08",
      status: "Vendido",
      price: "6,63 UF/m2",
      surface: "2.488,46 m2",
      src: [
        require("assets/img/gallery/lotes/G/G08/G08_1.jpg"),
        require("assets/img/gallery/lotes/G/G08/G08_2.jpg"),
        require("assets/img/gallery/lotes/G/G08/G08_3.jpg"),
        require("assets/img/gallery/lotes/G/G08/G08_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 08 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.488,46 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 62,
      name: "g09",
      title: "Fundo Zapallar, Manzana G. Lote 09",
      description: "Manzana G. Lote 09",
      status: "Vendido",
      price: "6,23 UF/m2",
      surface: "2.488,7 m2",
      src: [
        require("assets/img/gallery/lotes/G/G09/G09_1.jpg"),
        require("assets/img/gallery/lotes/G/G09/G09_2.jpg"),
        require("assets/img/gallery/lotes/G/G09/G09_3.jpg"),
        require("assets/img/gallery/lotes/G/G09/G09_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 09 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.488,7 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 63,
      name: "g10",
      title: "Fundo Zapallar, Manzana G. Lote 10",
      description: "Manzana G. Lote 10",
      status: "Vendido",
      price: "5,38 UF/m2",
      surface: "2.600,62 m2",
      src: [
        require("assets/img/gallery/lotes/G/G10/G10_1.jpg"),
        require("assets/img/gallery/lotes/G/G10/G10_2.jpg"),
        require("assets/img/gallery/lotes/G/G10/G10_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.600,62 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 64,
      name: "g11",
      title: "Fundo Zapallar, Manzana G. Lote 11",
      description: "Manzana G. Lote 11",
      status: "Vendido",
      price: "6,37 UF/m2",
      surface: "2.041,88 m2",
      src: [
        require("assets/img/gallery/lotes/G/G11/G11_1.jpg"),
        require("assets/img/gallery/lotes/G/G11/G11_2.jpg"),
        require("assets/img/gallery/lotes/G/G11/G11_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.041,88 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 65,
      name: "g12",
      title: "Fundo Zapallar, Manzana G. Lote 12",
      description: "Manzana G. Lote 12",
      status: "Vendido",
      price: "5,75 UF/m2",
      surface: "2.174,93 m2",
      src: [
        require("assets/img/gallery/lotes/G/G12/G12_1.jpg"),
        require("assets/img/gallery/lotes/G/G12/G12_2.jpg"),
        require("assets/img/gallery/lotes/G/G12/G12_3.jpg"),
        require("assets/img/gallery/lotes/G/G12/G12_4.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 12 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'> Superficie: 2.174,93 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 66,
      name: "g13",
      title: "Fundo Zapallar, Manzana G. Lote 13",
      description: "Manzana G. Lote 13",
      status: "Disponible",
      price: "5,19 UF/m2",
      surface: "2.214,33 m2",
      src: [
        require("assets/img/gallery/lotes/G/G13/G13_1.jpg"),
        require("assets/img/gallery/lotes/G/G13/G13_2.jpg"),
        require("assets/img/gallery/lotes/G/G13/G13_3.jpg"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana G. Lote 13 </h4> <p> Estado: Disponible </p> <p class='ultimate-p'> Superficie: 2.214,33 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 67,
      name: "mc1",
      title: "Fundo Zapallar, MC1",
      description: "MC1",
      status: "No Disponible",
      price: "0,38 UF/m2",
      surface: "39.422,5 m2",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>MC1 </h4> <p> Estado: No Disponible </p> <p class='ultimate-p'> Superficie: 39.422,5 m2 </p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 68,
      name: "a01",
      title: "Fundo Zapallar, Manzana A. Lote 1",
      description: "Manzana A. Lote 1",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 1 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 69,
      name: "a02",
      title: "Fundo Zapallar, Manzana A. Lote 2",
      description: "Manzana A. Lote 2",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 2 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 70,
      name: "a03",
      title: "Fundo Zapallar, Manzana A. Lote 3",
      description: "Manzana A. Lote 3",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 3 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 71,
      name: "a04",
      title: "Fundo Zapallar, Manzana A. Lote 4",
      description: "Manzana A. Lote 4",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 4 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 72,
      name: "a05",
      title: "Fundo Zapallar, Manzana A. Lote 5",
      description: "Manzana A. Lote 5",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 5 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 73,
      name: "a06",
      title: "Fundo Zapallar, Manzana A. Lote 6",
      description: "Manzana A. Lote 6",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 6 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 74,
      name: "a07",
      title: "Fundo Zapallar, Manzana A. Lote 7",
      description: "Manzana A. Lote 7",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 7 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 75,
      name: "a08",
      title: "Fundo Zapallar, Manzana A. Lote 8",
      description: "Manzana A. Lote 8",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 8 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 76,
      name: "a09",
      title: "Fundo Zapallar, Manzana A. Lote 9",
      description: "Manzana A. Lote 9",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 9 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 77,
      name: "a10",
      title: "Fundo Zapallar, Manzana A. Lote 10",
      description: "Manzana A. Lote 10",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 78,
      name: "a11",
      title: "Fundo Zapallar, Manzana A. Lote 11",
      description: "Manzana A. Lote 11",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana A. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 79,
      name: "b01",
      title: "Fundo Zapallar, Manzana B. Lote 1",
      description: "Manzana B. Lote 1",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 1 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 80,
      name: "b02",
      title: "Fundo Zapallar, Manzana B. Lote 2",
      description: "Manzana B. Lote 2",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 2 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 81,
      name: "b03",
      title: "Fundo Zapallar, Manzana B. Lote 3",
      description: "Manzana B. Lote 3",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 3 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 82,
      name: "b04",
      title: "Fundo Zapallar, Manzana B. Lote 4",
      description: "Manzana B. Lote 4",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 4 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 83,
      name: "b05",
      title: "Fundo Zapallar, Manzana B. Lote 5",
      description: "Manzana B. Lote 5",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 5 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 84,
      name: "b06",
      title: "Fundo Zapallar, Manzana B. Lote 6",
      description: "Manzana B. Lote 6",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 6 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 85,
      name: "b07",
      title: "Fundo Zapallar, Manzana B. Lote 7",
      description: "Manzana B. Lote 7",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 7 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 86,
      name: "b08",
      title: "Fundo Zapallar, Manzana B. Lote 8",
      description: "Manzana B. Lote 8",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 8 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 87,
      name: "b09",
      title: "Fundo Zapallar, Manzana B. Lote 9",
      description: "Manzana B. Lote 9",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 9 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 88,
      name: "b10",
      title: "Fundo Zapallar, Manzana B. Lote 10",
      description: "Manzana B. Lote 10",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 89,
      name: "b11",
      title: "Fundo Zapallar, Manzana B. Lote 11",
      description: "Manzana B. Lote 11",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 90,
      name: "b12",
      title: "Fundo Zapallar, Manzana B. Lote 12",
      description: "Manzana B. Lote 12",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana B. Lote 12 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 91,
      name: "c01",
      title: "Fundo Zapallar, Manzana C. Lote 1",
      description: "Manzana C. Lote 1",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 1 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 92,
      name: "c02",
      title: "Fundo Zapallar, Manzana C. Lote 2",
      description: "Manzana C. Lote 2",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 2 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 93,
      name: "c03",
      title: "Fundo Zapallar, Manzana C. Lote 3",
      description: "Manzana C. Lote 3",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 3 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 94,
      name: "c04",
      title: "Fundo Zapallar, Manzana C. Lote 4",
      description: "Manzana C. Lote 4",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 4 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 95,
      name: "c05",
      title: "Fundo Zapallar, Manzana C. Lote 5",
      description: "Manzana C. Lote 5",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 5 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 96,
      name: "c06",
      title: "Fundo Zapallar, Manzana C. Lote 6",
      description: "Manzana C. Lote 6",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 6 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 97,
      name: "c07",
      title: "Fundo Zapallar, Manzana C. Lote 7",
      description: "Manzana C. Lote 7",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 7 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 98,
      name: "c08",
      title: "Fundo Zapallar, Manzana C. Lote 8",
      description: "Manzana C. Lote 8",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 8 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 99,
      name: "c09",
      title: "Fundo Zapallar, Manzana C. Lote 9",
      description: "Manzana C. Lote 9",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 9 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 100,
      name: "c10",
      title: "Fundo Zapallar, Manzana C. Lote 10",
      description: "Manzana C. Lote 10",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 101,
      name: "c11",
      title: "Fundo Zapallar, Manzana C. Lote 11",
      description: "Manzana C. Lote 11",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 102,
      name: "c12",
      title: "Fundo Zapallar, Manzana C. Lote 12",
      description: "Manzana C. Lote 12",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 12 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 103,
      name: "c13",
      title: "Fundo Zapallar, Manzana C. Lote 13",
      description: "Manzana C. Lote 13",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 13 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 104,
      name: "c14",
      title: "Fundo Zapallar, Manzana C. Lote 14",
      description: "Manzana C. Lote 14",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 14 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 105,
      name: "c15",
      title: "Fundo Zapallar, Manzana C. Lote 15",
      description: "Manzana C. Lote 15",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 15 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 106,
      name: "c16",
      title: "Fundo Zapallar, Manzana C. Lote 16",
      description: "Manzana C. Lote 16",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 16 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 107,
      name: "c17",
      title: "Fundo Zapallar, Manzana C. Lote 17",
      description: "Manzana C. Lote 17",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 17 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 108,
      name: "c18",
      title: "Fundo Zapallar, Manzana C. Lote 18",
      description: "Manzana C. Lote 18",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 18 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 109,
      name: "c19",
      title: "Fundo Zapallar, Manzana C. Lote 19",
      description: "Manzana C. Lote 19",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 19 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 110,
      name: "c20",
      title: "Fundo Zapallar, Manzana C. Lote 20",
      description: "Manzana C. Lote 20",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 20 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 111,
      name: "c21",
      title: "Fundo Zapallar, Manzana C. Lote 21",
      description: "Manzana C. Lote 21",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 21 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 112,
      name: "c22",
      title: "Fundo Zapallar, Manzana C. Lote 22",
      description: "Manzana C. Lote 22",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 22 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 113,
      name: "c23",
      title: "Fundo Zapallar, Manzana C. Lote 23",
      description: "Manzana C. Lote 23",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 23 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 114,
      name: "c24",
      title: "Fundo Zapallar, Manzana C. Lote 24",
      description: "Manzana C. Lote 24",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 24 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 115,
      name: "c25",
      title: "Fundo Zapallar, Manzana C. Lote 25",
      description: "Manzana C. Lote 25",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 25 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 116,
      name: "c26",
      title: "Fundo Zapallar, Manzana C. Lote 26",
      description: "Manzana C. Lote 26",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana C. Lote 26 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 117,
      name: "d08",
      title: "Fundo Zapallar, Manzana D. Lote 8",
      description: "Manzana D. Lote 8",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 8 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 118,
      name: "d09",
      title: "Fundo Zapallar, Manzana D. Lote 9",
      description: "Manzana D. Lote 9",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 9 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 119,
      name: "d10",
      title: "Fundo Zapallar, Manzana D. Lote 10",
      description: "Manzana D. Lote 10",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 10 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 120,
      name: "d11",
      title: "Fundo Zapallar, Manzana D. Lote 11",
      description: "Manzana D. Lote 11",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana D. Lote 11 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 121,
      name: "e02",
      title: "Fundo Zapallar, Manzana E. Lote 2",
      description: "Manzana E. Lote 2",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 2 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 122,
      name: "e03",
      title: "Fundo Zapallar, Manzana E. Lote 3",
      description: "Manzana E. Lote 3",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 3 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 123,
      name: "e04",
      title: "Fundo Zapallar, Manzana E. Lote 4",
      description: "Manzana E. Lote 4",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 4 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 124,
      name: "e05",
      title: "Fundo Zapallar, Manzana E. Lote 5",
      description: "Manzana E. Lote 5",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 5 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 125,
      name: "e06",
      title: "Fundo Zapallar, Manzana E. Lote 6",
      description: "Manzana E. Lote 6",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 6 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
    {
      id: 126,
      name: "e07",
      title: "Fundo Zapallar, Manzana E. Lote 7",
      description: "Manzana E. Lote 7",
      status: "Vendido",
      src: [
        require("assets/img/gallery/lotes/vendidos-1.JPG"),
        require("assets/img/gallery/lotes/vendidos-2.JPG"),
        require("assets/img/gallery/lotes/vendidos-3.JPG"),
        require("assets/img/gallery/lotes/vendidos-4.JPG"),
      ],
      altText: "",
      caption:
        `<div class='track-mask'><h4>Manzana E. Lote 7 </h4> <p> Estado: Vendido </p> <p class='ultimate-p'></p> <a href="javascript:;" class='lg-close lg-kick-gallery'>Volver vista Aérea</a> <a href=${Plano} target='_blank()'>Descargar plano</a></div>`,
    },
  ];

  const [allNewItems, setItems] = useState(items);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const mobileSettings = () => {
    const settings = {
      controls: true,
      showCloseIcon: true,
      download: true,
    };

    return settings;
  };

  const backgroundCloud = require("assets/img/gallery/mapa-zapallar.jpg");

  const onClickGallery = (itemLote) => {
    setIdLoteSelect(itemLote);
  };

  const getItems = () => {
    return allNewItems.map((item) => {
      let html = [];
      if (Array.isArray(item.src)) {
        let posfijo = "";
        html = item.src.map((itemJ, j) => {
          posfijo = j < 1 ? "" : `-${j}`;
          return (
            <div
              data-src={itemJ}
              className={"gallery-" + item.id + posfijo}
              data-sub-html={item.caption}
              key={j}
            >
              <img className="track-image" src={itemJ} />
            </div>
          );
        });
      } else {
        html.push(
          <div
            data-src={item.src}
            className={"gallery-" + item.id}
            data-sub-html={item.caption}
          >
            <img className="track-image" src={item.src} />
          </div>
        );
      }
      return html;
    });
  };

  const onAfterSlide = useCallback(() => {
    lightGallery.current.refresh();
    const caption = document.querySelector(".lg-kick-gallery");
    caption.addEventListener("click", () => {
      document.querySelector("#lg-close-"+lightGallery.current.lgId).click();
    });
  }, []);

  const onBeforeOpen = useCallback((detail) => {
    setIdLoteSelect(0);
  }, []);

  const onAfterOpen = useCallback(
    (detail) => {
      setIdLoteSelect(0);
    },
    [allNewItems]
  );

  const onAfterClose = useCallback(
    (detail) => {
      setItems(items);
    },
    [allNewItems]
  );

  useEffect(() => {
    if (idLoteSelect > 0) {
      let idIndex = "";
      idIndex = items.filter((item) => item.id === idLoteSelect);
      setItems(idIndex);
    }
  }, [idLoteSelect]);

  useEffect(
    (detail) => {
      lightGallery.current.refresh();
      if (idLoteSelect > 0) {
        let gallery = document
          .querySelector(".gallery-" + idLoteSelect)
          .click();
        const caption = document.querySelector(".lg-kick-gallery");
        caption.addEventListener("click", () => {
          document.querySelector("#lg-close-"+lightGallery.current.lgId).click();
        });
      }
    },
    [allNewItems]
  );

  const [isActive, setIsActive] = useState(false);

  const activeMap = (event) => {
    // 👇️ toggle isActive state on click
    if (!isActive) {
      setIsActive((current) => !current);
    }
  };

  return (
    <div>
      <div
        className={
          isActive
            ? "section map text-center active"
            : "section map text-center"
        }
        id="masterplan"
        onClick={activeMap}
      >
        <div className="padre">
          <div className="legends">
            <div className="legends-item sold">Vendido</div>
            <div className="legends-item available">Disponible</div>
            <div className="legends-item not-available">No Disponible</div>
            <p className="legends-text d-lg-block d-none">
              las imágenes exhibidas son de caracter ilustrativo, referencial y
              no constituyen necesariamente una representacion exacta de la
              realidad
            </p>
          </div>
          <img src={backgroundCloud} className="background-padre" alt="" />
          <div className="circles">
            {items.map((item) => (
              <div
                className={`circle ${
                  item.status === "No Disponible"
                    ? "reservado"
                    : item.status.toLowerCase()
                }`}
                id={item.name}
                onClick={() => onClickGallery(item.id)}
                data-active={item.id}
                key={item.id}
              >
                <span>{item.name.toUpperCase()}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="gallery-container">
          <LightGallery
            speed={500}
            plugins={[lgThumbnail, lgZoom]}
            onInit={onInit}
            mobileSettings={mobileSettings}
            // onAfterOpen={onAfterOpen}
            // onBeforeOpen={onBeforeOpen}
            // onAfterClose={onAfterClose}
            onAfterSlide={onAfterSlide}
          >
            {getItems()}
          </LightGallery>
        </div>
      </div>
      <div className="legends mobile">
        <div className="legends-item sold">Vendido</div>
        <div className="legends-item available">Disponible</div>
        <div className="legends-item not-available">No Disponible</div>
      </div>
      <p className="legends-text-mobile d-block d-lg-none">
        las imágenes exhibidas son de caracter ilustrativo, referencial y no
        constituyen necesariamente una representacion exacta de la realidad
      </p>
    </div>
  );
};
export default GalleryMaps;
